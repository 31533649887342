import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import Title from '../Title';
import { CtaLinkRoundedOutline } from '../Buttons/style';
import { WorkWrapper } from './style';
import WorkCard from './WorkCard';

const index = ({ title, desc, data }) => {
  return (
    <WorkWrapper>
      <div className="container">
        <Title title={title} desc={desc} align="center" />
        <div className="WorkGrid">
          {!data.nodes ? (
            <div>No data - `./component/Work/index.js`</div>
          ) : (
            data.nodes.map((node) => (
              <WorkCard
                key={uuidv4()}
                img={node.frontmatter.showCaseImage}
                logo={node.frontmatter.companyLogo}
                caption={node.frontmatter.keywords}
                title={node.frontmatter.shortTitle}
                caseStudy={`/case-studies${node.fields.slug}`}
                website={node.frontmatter.website}
              />
            ))
          )}
        </div>

        <div className="d-flex justify-content-center ">
          <CtaLinkRoundedOutline to="/case-studies">
            See All
          </CtaLinkRoundedOutline>
        </div>
      </div>
    </WorkWrapper>
  );
};

export default index;
