import React from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import { TechnologiesWrapper, Card } from './style';

import { FrontendTech, BackendTech, MobilTech } from '../../config/constants';

const TechCard = (props) => {
  return (
    <Card>
      <img src={props.img} alt={`logo of ${props.title}`} />
      <div>
        <h4>{props.title}</h4>
        <p>{props.desc}</p>
      </div>
    </Card>
  );
};

const index = (props) => {
  const totalStack = [...FrontendTech, ...BackendTech, ...MobilTech];

  return (
    <TechnologiesWrapper>
      <div className="container">
        <Title title={props.title} desc={props.desc} highlightBorder={false} />
        {props.projectTech ? (
          <div className="row">
            {totalStack.map(({ id, img, title, desc }) => {
              for (var i = 0; i < props.projectTech.length; i++) {
                if (
                  title.toUpperCase() ==
                  props.projectTech[i].toUpperCase().replace(/\s+/g, '')
                ) {
                  return (
                    <div key={id} className="col">
                      <TechCard img={img} title={title} desc={desc} />
                    </div>
                  );
                }
              }
            })}
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <h3>Frontend Development</h3>

              {FrontendTech.map(({ id, img, title, desc }) => (
                <TechCard key={id} img={img} title={title} desc={desc} />
              ))}
            </div>

            <div className="col">
              <h3>Backend Development</h3>

              {BackendTech.map(({ id, img, title, desc }) => (
                <TechCard key={id} img={img} title={title} desc={desc} />
              ))}
            </div>

            <div className="col">
              <h3>Mobile App Development</h3>

              {MobilTech.map(({ id, img, title, desc }) => (
                <TechCard key={id} img={img} title={title} desc={desc} />
              ))}
            </div>
          </div>
        )}
      </div>
    </TechnologiesWrapper>
  );
};

TechCard.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default index;
