import styled from 'styled-components';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const ProcessWrapper = styled(Wrapper)`
  display: block;
  overflow: unset;

  & .process-img-box {
    background: linear-gradient(180deg, #2d3653, #1b1d34);
    border-radius: 1rem;
    max-width: 400px;
    position: sticky;
    top: 8rem;

    & img {
      width: 100%;
    }
  }

  @media ${device.desktopS} {
    .process-img-box {
      max-width: 100%;
    }
  }
`;
