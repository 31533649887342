import React from 'react';

import Title from '../Title';
import FaqList from '../FaqList';
import { ProcessWrapper } from './style';

const index = () => {
  const processFaqItems = [
    {
      question: 'Capture and understand',
      answer:
        "During this step, our team listens. We take special care to deeply understand your existing website or software solution and ask a series of targeted questions about your vision of the new product. We'll then perform industry-specific research in your domain to better prioritize the unique values of your business model and use this information to build a better deliverable.",
      open: true,
    },
    {
      question: 'Prepare solution strategy',
      answer:
        'Up-front solution planning lets us finish your project faster and for less. At this step, our engineers begin translating your project requirements into technological terms. Then, we look into hundreds of software options to determine the optimum solution for your needs and carefully examine future adaptations and potential changes to ensure your software stays functional and long-lasting.',
    },
    {
      question: 'Estimate and engagement plan',
      answer:
        'After we establish a strategy and potential software routes, we turn to the estimation process. It is vitally essential for our team to give you a clear picture of what your end-services will cost. To that end, we provide a thorough estimate, cost-sheet, and engagement plan to help align your expectations and stay within your budget.',
    },
    {
      question: 'Design, develop and test',
      answer:
        "This is where we call in the big guns. Our professional software engineers will design, develop, and test your product. They've built hundreds of performant, robust applications across various domains and have a deep knowledge of industry best practices to ensure quality results. You get a lean, durable, and thoroughly tested end-product, and we get the satisfaction of building something incredible.",
    },
    {
      question: 'Ongoing support',
      answer:
        "Software is just like everything else - it needs ongoing monitoring and support to perform at its best. We offer a robust maintenance program to ensure your software functions as intended far into the future. And we also don't break the bank - most minor bug fixes, software updates, and patches are free or reasonably priced.",
    },
  ];

  return (
    <ProcessWrapper>
      <div className="container">
        <Title title="Our Process" />
        <div className="row">
          <div className="col-lg-5 mb-5 mb-lg-0">
            <div className="process-img-box">
              <img src="/img/process.svg" alt="Illustration of hand shake" />
            </div>
          </div>
          <div className="col-lg-7">
            <p className="pl-5 pr-3 pb-3">
              Our innovative IT process lets us serve you best at every step
              along the way. It includes the following steps:
            </p>
            <FaqList faqItems={processFaqItems} />
          </div>
        </div>
      </div>
    </ProcessWrapper>
  );
};

export default index;
