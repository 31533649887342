import React from 'react';
import Layout from '../layout/layout';
import SEO from '../components/SEO';
import { ROUTES } from '../config/constants';
import { graphql } from 'gatsby';

import HeroDoubleImg from '../components/HeroDoubleImg';
import Process from '../components/Process';
import Work from '../components/Work';
import Technologies from '../components/Technologies';
import Achievements from '../components/Achievements';

const work = ({ data }) => {
  return (
    <Layout pageTitle="Our Work">
      <SEO title={ROUTES.work.title} description={ROUTES.work.desc} />

      <HeroDoubleImg pageTitle="Our Work" reverseImgLayout>
        <div className="img-group">
          <img
            src="/img/optimum/Optimum-Team-As-A-Service.webp"
            alt="employees working on laptop"
          />
          <img
            src="/img/optimum/Optimum-Engineers-Task-Discussion.webp"
            alt="Optimum Futirist engineers discussing their task"
          />
        </div>
        <p>
          We are your SWAT team for full-stack, high-speed web application
          design and development. Optimum Futurist provides services for mid to
          large multi-national enterprises. Our software development and
          consulting process is an improvised version of the industry-standard
          best practices.
        </p>
      </HeroDoubleImg>
      <Process />
      <Work masonLayout={true} title="Case Studies" data={data.Casestudies} />
      <Technologies
        title="Technologies we use"
        desc="These are the technologies we use to develop robust digital solutions for you and your business in our day-to-day life."
      />
      <Achievements
        title="Let’s take your digital experience to the next level"
        desc="IT isn't just something we do; our professional consultants live, eat, and breathe their work. We're committed to providing passionate, personalized service that elevates your business and improves your digital outcomes."
        highlightBorder={true}
        showStamp={false}
        showProjectRequest={false}
        hideOnMobile={true}
        maxw="44rem"
      />
    </Layout>
  );
};

export default work;

export const query = graphql`
  {
    Casestudies: allMarkdownRemark(
      limit: 2
      filter: { fileAbsolutePath: { regex: "/case-studies/.*.md$/" } }
    ) {
      nodes {
        frontmatter {
          date
          title
          shortTitle
          showCaseImage
          companyLogo
          keywords
          website
        }
        fields {
          slug
        }
      }
    }
  }
`;
