import styled from 'styled-components';
import { device, size } from '../../layout/device';
import colors from '../../layout/globalColors';
import { Wrapper } from '../../layout/global-style';

export const HeroWrapper = styled(Wrapper).attrs(() => ({ tabIndex: 0 }))`
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 64%;
    left: 0;
    transform: translate(-50%, -100%);
    z-index: -1;
    width: 4rem;
    height: 4rem;
    border-radius: 1rem;
    background: linear-gradient(
      180deg,
      ${colors.spaceCadetDark},
      ${colors.spaceCadet2Dark}
    );
    box-shadow: 0 14px 50px rgba(0, 0, 0, 0.07);

    @media ${device.laptop} {
      width: 7rem;
      height: 7rem;
      transform: translate(-45%, -200%);
    }
  }

  button {
    background-color: ${colors.primary};
  }

  button svg {
    font-size: 1.6rem;
  }
  @media ${device.tablet} {
    padding: 4rem 2rem;
  }

  @media ${device.laptop} {
    padding: 4rem 2rem;
  }

  @media ${device.laptopL} {
    padding: 6rem 8rem 10rem 9rem;
  }

  @media ${device.desktopS} {
    min-height: 62rem;
    padding-left: 12rem;
    padding-bottom: 6rem;
  }

  @media ${device.desktop} {
    max-width: ${size.desktop};
    margin: auto;
  }

  & p {
    margin: 1.25rem 0 1.25rem;
    font-size: 1.1rem;
    line-height: 1.66;

    @media ${device.laptopL} {
      font-size: 1.3rem;
      line-height: 1.7;
      max-width: 38rem;
    }
  }
`;

export const ImgGroup = styled.div`
  display: block;
  position: relative;
  min-height: 20rem;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -20vh;
    left: 60vw;
    z-index: -1;
    width: 40vw;
    height: 50vh;
    border-radius: 1rem;
    box-shadow: 0 14px 50px rgba(0, 0, 0, 0.15);
    background: linear-gradient(
      180deg,
      ${colors.spaceCadetDark},
      ${colors.spaceCadet2Dark}
    );

    @media ${device.laptop} {
      top: 5vh;
      left: 0;
      z-index: -1;
      width: 40vw;
      height: 50vh;
    }
  }

  > .gatsby-image-wrapper {
    position: absolute !important;
  }

  .img-top {
    width: 23rem;
    height: 16rem;
    top: 5vh;
    right: 0;

    @media ${device.laptop} {
      width: 30rem;
      height: 20rem;
      right: -10vw;
      top: -3rem;
    }

    @media ${device.desktop} {
      right: -5vw;
      top: -5rem;
    }

  }

  .img-mid {
    width: 18rem;
    height: 12rem;
    top: 10rem;
    right: 14rem;
    z-index: 1;
    box-shadow: rgba(0,0,0,0.4) 8px 0px 10px;

    @media ${device.laptop} {
      width: 15rem;
      height: 10rem;
      box-shadow: none;
    }

    @media ${device.laptopL} {
      width: 22rem;
      height: 15rem;
      top: 18rem;
      right: 11rem;  
    }
  }

  .img-bottom {
    width: 7rem;
    height: 5rem;
    bottom: -8rem;
    left: auto;
    right: 0;

    img { border-radius: 0.3rem !important;}

    @media ${device.laptopL} {
      bottom: -16rem;
      left: -10rem;
      right: auto;
    }
  }

  & img {
    border-radius: 0.5rem;

    @media ${device.laptop} {
      border-radius: 1rem;
    }
  }

}
`;

export const BgLine = styled.div`
  position: absolute;
  left: 50%;
  width: 100vw;
  object-fit: cover;
  top: 45%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  & img {
    width: 100vw;
  }

  @media ${device.mobile} {
    top: 80%;
  }

  @media ${device.desktopS} {
    top: 45%;
  }
`;
