import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types';
import { FaqWrapper, Article } from './style';

const FAQ = ({ faqItems }) => {
  const [supportFaqList, setSupportFaqList] = useState(faqItems);

  const toggleFAQ = (index) => {
    setSupportFaqList(
      supportFaqList.map((faq, i) => {
        const item = faq;
        if (i === index) {
          item.open = !item.open;
        } else {
          item.open = false;
        }

        return item;
      }),
    );
  };

  return (
    <FaqWrapper>
      {supportFaqList.map((faq, index) => (
        <Article key={uuidv4()} faqState={faq.open}>
          <h3 className="faq-question" onClick={() => toggleFAQ(index)}>
            {faq.question}
          </h3>
          <p className="faq-answer">{faq.answer}</p>
        </Article>
      ))}
    </FaqWrapper>
  );
};

FAQ.propTypes = {
  faqItems: PropTypes.array,
};

export default FAQ;
