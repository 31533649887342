import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const TechnologiesWrapper = styled(Wrapper)`
  display: block;
  h3 {
    font-size: 1.5rem;
    color: ${colors.cadetBlue};
    margin: 2rem 0;
    @media ${device.desktopS} {
      font-size: 2rem;
    }
  }
`;

export const Card = styled.article`
  display: flex;
  min-width: 18rem;
  align-items: flex-start;
  margin: 1rem -1rem 0.6rem;
  padding: 1rem 0;
  border-radius: 1rem;
  transition: all 0.3s ease-in;

  & img,
  & > div {
    margin: 0 1rem;
  }

  & img {
    border-radius: 1rem;
    max-width: 70px;
    margin-top: 0.4rem;
  }

  & h4 {
    font-size: 1.6rem;
    color: ${colors.cadetBlue};
    margin-bottom: 0.5rem;
  }

  p {
    color: ${colors.lightGrey};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all 0.3s ease-in;
  }

  @media ${device.tablet} {
    img {
      max-width: 90px;
    }
  }

  &:hover {
    box-shadow: 0 14px 50px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    transition: all 0.3s ease-in;

    p {
      -webkit-line-clamp: 10;
      transition: all 0.3s ease-in;
    }
  }
`;
