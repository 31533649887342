import styled, { css } from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const WorkWrapper = styled(Wrapper)`
  padding-top: 8rem;
  padding-bottom: 18rem;

  & .WorkGrid {
    display: grid;
    grid-template-columns: 1fr;
  }

  & .WorkGrid + div button {
    margin-top: 5rem;
  }

  nav {
    margin-top: 8rem;
  }

  .pagination {
    list-style: none;
    display: flex;
    justify-content: center;

    li {
      display: grid;
      place-items: center;
      width: 4rem;
      height: 4rem;
      border: 2px solid ${colors.cadetBlue};
      border-radius: 50%;
      margin: 0 1rem;
      font-weight: 500;
      font-size: 1.2rem;
      cursor: pointer;
    }

    li.active,
    li:hover {
      border: 2px solid ${colors.secondary};
    }

    li.active {
      background: #29304a;
    }

    li.inactive {
      opacity: 0.6;
    }
  }

  @media ${device.tablet} {
    .WorkGrid {
      grid-template-columns: 1fr;
      grid-gap: 1.25rem;
    }
  }

  @media ${device.laptop} {
    .WorkGrid {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
`;

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  background: linear-gradient(
    180deg,
    ${colors.spaceCadetDark},
    ${colors.spaceCadet2Dark}
  );
  margin: 3rem 0 0;
  box-shadow: 0 0.9rem 50rem rgba(0, 0, 0, 0.6);

  @media ${device.laptop} {
    flex-direction: ${(props) => props.theme.flexdir};

    &:nth-of-type(2n) {
      flex-direction: row-reverse;

      & img {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
      }
    }

    &:nth-of-type(2n + 1) img {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;
    }
  }

  ${(props) =>
    props.masonLayout &&
    css`
      background: transparent;
      margin: 3rem -1rem;
      box-shadow: none;
      @media ${device.laptop} {
        &:nth-of-type(2n) {
          & img {
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
          }
        }

        &:nth-of-type(2n + 1) img {
          border-top-right-radius: inherit;
          border-bottom-right-radius: inherit;
          border-top-left-radius: 1rem;
          border-bottom-left-radius: 1rem;
        }
      }
    `}

  .btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 2rem -0.5rem -1rem;

    & > * {
      margin-top: 0;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 1rem;
      padding: 0.5rem 1.2rem;
    }
  }
`;

export const CardImgBox = styled.div`
  display: flex;
  flex-shrink: 0;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;

  & img {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    height: auto;
    width: 100%;
  }

  ${(props) =>
    props.masonLayout &&
    css`
      margin: 0 1rem;
    `}

  @media ${device.laptop} {
    width: 50%;
    border-radius: inherit;

    & img {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
      width: 100%;
      object-fit: cover;
    }

    ${(props) => {
      return (
        props.masonLayout &&
        css`
          width: 50%;
          box-shadow: 0 0.9rem 50rem rgba(21, 82, 215, 0.15);

          @media ${device.laptopL} {
            width: 64%;
            height: 100%;
          }
        `
      );
    }}
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 2.25rem 1.25rem;

  ${(props) =>
    props.masonLayout &&
    css`
      background: linear-gradient(
        180deg,
        ${colors.spaceCadetDark},
        ${colors.spaceCadet2Dark}
      );
      margin: 0 1rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      box-shadow: 0 0.9rem 50rem rgba(0, 0, 0, 0.15);

      @media ${device.laptop} {
        border-radius: 1rem;
      }
    `}

  & > img {
    width: 140px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 2rem;
    object-position: left;
  }

  & button {
    margin-top: 2rem;
    text-transform: uppercase;
    color: ${colors.white};
    font-size: 1.1rem;
  }

  & a {
    min-width: 16rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1.9rem;
    margin-top: 1rem;
  }

  @media ${device.laptop} {
    padding: 4rem;

    & > img {
      margin-bottom: 4rem;
    }

    & button {
      margin-top: 0;
    }
  }

  @media ${device.laptopL} {
    padding: 4rem 2rem;
  }

  @media ${device.desktopS} {
    padding: 4rem;
  }
`;

export const CardTitle = styled.div.attrs(() => ({ tabIndex: 0 }))`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 2rem;
`;

export const Caption = styled.p`
  font-size: 0.8rem;
  margin-bottom: 0.5rem;

  @media ${device.mobile} {
    font-size: 1rem;
  }
`;

export const CardTitleText = styled.h3.attrs(() => ({ tabIndex: 0 }))`
  font-size: 1.5rem;

  @media ${device.tablet} {
    font-size: 2rem;
  }

  @media ${device.laptop} {
    font-size: 2.5rem;
  }
`;
