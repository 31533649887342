import React from 'react';
import PropTypes from 'prop-types';
// import { CgChevronRightO } from '@react-icons/all-files/cg/CgChevronRightO';

import Button from '../Buttons';
import { CtaLinkFillType } from '../Buttons/style';
import {
  Card,
  CardImgBox,
  CardBody,
  CardTitle,
  Caption,
  CardTitleText,
} from './style';

const WorkCard = (props) => {
  let ButtonTemplate;
  if (props.masonLayout) {
    ButtonTemplate = (
      <div className="btn-group">
        {props.website && (
          <Button
            as="a"
            href={props.website}
            target="_blank"
            rel="noopener noreferrer nofollow"
            btnText="Visit Website"
            btnType="rounded-outline"
          />
        )}
        <CtaLinkFillType to={props.caseStudy}>Read More</CtaLinkFillType>
      </div>
    );
  } else {
    ButtonTemplate = (
      <div className="btn-group">
        <CtaLinkFillType to={props.caseStudy}>Read More</CtaLinkFillType>
        {props.website && (
          <CtaLinkFillType
            as="a"
            href={props.website}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Visit Website
          </CtaLinkFillType>
        )}
      </div>
    );
  }

  return (
    <Card masonLayout={props.masonLayout}>
      <CardImgBox masonLayout={props.masonLayout}>
        <img
          src={props.img}
          width="684"
          alt={`Presentational image to represent one of our projects ${props.title}`}
          tabIndex="0"
        />
      </CardImgBox>
      <CardBody masonLayout={props.masonLayout}>
        <img src={props.logo} alt={`logo of ${props.title}`} tabIndex="0" />
        <CardTitle>
          <Caption> {props.caption} </Caption>
          <CardTitleText>{props.title} </CardTitleText>
        </CardTitle>
        {ButtonTemplate}
        {/* <ButtonGroup masonLayout={props.masonLayout} /> */}
        {/* <div className="btn-group">
          <CtaLinkFillType to={props.caseStudy}>
            Read Case Study
          </CtaLinkFillType>
          {props.website && (
            <CtaBtn
              as="a"
              href={props.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              CHECK WEBSITE
              <CgChevronRightO />
            </CtaBtn>
          )}
        </div> */}

        {/* {props.masonLayout ? (
          <div className="btn-group">
            <Button
              as="a"
              href={props.website}
              target="_blank"
              rel="noopener noreferrer"
              btnText="Visit Website"
              btnType="rounded-outline"
            />
            <CtaLinkFillType to={props.caseStudy}>
              Read Case Study
            </CtaLinkFillType>
          </div>
        ) : (
          <div className="btn-group">
            <CtaLinkFillType to={props.caseStudy}>
              Read Case Study
            </CtaLinkFillType>
            <CtaBtn
              as="a"
              href={props.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              CHECK WEBSITE
              <CgChevronRightO />
            </CtaBtn>
          </div>
        )} */}
      </CardBody>
    </Card>
  );
};
export default WorkCard;

Card.defaultProps = {
  theme: {
    flexdir: 'row',
  },
};

WorkCard.propTypes = {
  img: PropTypes.string,
  logo: PropTypes.string,
  caption: PropTypes.string,
  title: PropTypes.string,
  btnType: PropTypes.string,
  btnText: PropTypes.string,
  website: PropTypes.string,
  caseStudy: PropTypes.string,
  masonLayout: PropTypes.bool,
};
