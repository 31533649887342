import styled, { css } from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';

export const FaqWrapper = styled.div`
  @media ${device.laptop} {
    padding-left: 5rem;
  }
`;

export const Article = styled.div`
  padding-left: 2rem;
  position: relative;
  transition: all 0.3s ease-in;

  @media ${device.laptop} {
    padding-left: 3rem;
  }
  h3 {
    position: relative;
    font-size: 1.2rem;
    margin: 0;
    padding: 1rem 3rem 1rem 0;
    cursor: pointer;
    color: ${colors.cadetBlue};

    @media ${device.laptop} {
      font-size: 1.6rem;
    }
  }

  p {
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
    font-size: 0.9rem;
    line-height: 1.5;
    color: ${colors.lightGrey};

    @media ${device.laptop} {
      font-size: 1.2rem;
    }
  }

  &::before {
    content: '';
    width: 0.6rem;
    height: 0.6rem;
    background: ${colors.crayolaGold};
    display: flex;
    border-radius: 0.6rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-in;
  }

  ${(props) => {
    if (props.faqState) {
      return css`
        .faq-answer {
          max-height: 60rem;
          opacity: 1;
          padding: 0 3rem 1.5rem 0;
        }

        &::before {
          content: '';
          width: 0.25rem;
          height: 100%;
          transition: all 0.3s ease-in;
        }

        @media ${device.Laptop} {
          &::before {
            width: 0.4rem;
          }
        }
      `;
    }
  }}
`;
